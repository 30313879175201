const [ d, w ] = [ document, window ];

const domReady = TPL => false;

const jqInit = () => false;

export default function COMPANY () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
  $(() => jqInit());
}