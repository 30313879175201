import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import GET_ARTICLES from "../class/getArticles";
import GET_INSTAGRAM from '../class/getInstagram'

const [w, d, mq] = [window,document,window.matchMedia( "(max-width: 1023px)" ) ];

class blocks {
  constructor() {
    gsap.registerPlugin( ScrollTrigger );
    this.init();
  }
  init () {
    this.about();
    this.works();
    this.news('.block-news__list[data-plugin="getArticles"]');
  }
  about () {
    if( !mq.matches ) {
      gsap.fromTo('.block-about__img .img01', {
        y: '-10%'
      },{ //アニメーションしたい要素を指定
        y: '22%',
        scrollTrigger: {
          trigger: '.block-about__img',//アニメーションが始まるトリガーとなる要素
          start: 'top bottom', //アニメーションが始まる位置を指定
          end: 'bottom top',
          scrub: true,
          // markers: true,
          onEnter: () => {
            d.querySelector('.block-about__img').classList.remove('is-hidden');
            d.querySelector('.block-about__img').animate([{opacity:'0'}, {opacity:'1'}], {duration:700});
          },
          onLeaveBack: () => {
            d.querySelector('.block-about__img').classList.add('is-hidden');
          },
        }
      });
    }
  }
  works () {
    if( !mq.matches ) {
      gsap.fromTo('.block-works__img .img01', {
        y: '0'
      },{ //アニメーションしたい要素を指定
        y: '40%',
        scrollTrigger: {
          trigger: '.block-works__img',//アニメーションが始まるトリガーとなる要素
          invalidateOnRefresh: true,
          start: 'top bottom', //アニメーションが始まる位置を指定
          end: 'bottom top',
          scrub: true,
          // markers: true,
          onEnter: () => {
            d.querySelector('.block-works__img.is-img01').classList.remove('is-hidden');
            d.querySelector('.block-works__img.is-img01').animate([{opacity:'0'}, {opacity:'1'}], {duration:700});
          },
          onLeaveBack: () => {
            d.querySelector('.block-works__img.is-img01').classList.add('is-hidden');
          },
        }
      });
      gsap.fromTo('.block-works__img .img02-01', {
        y: '-20%'
      },{ //アニメーションしたい要素を指定
        y: '0',
        ease: "none",
        scrollTrigger: {
          trigger: '.block-works__img',//アニメーションが始まるトリガーとなる要素
          invalidateOnRefresh: true,
          start: 'top bottom', //アニメーションが始まる位置を指定
          end: 'bottom top',
          scrub: true,
          // markers: true,
          onEnter: () => {
            d.querySelector('.block-works__img.is-img02').classList.remove('is-hidden');
            d.querySelector('.block-works__img.is-img02').animate([{opacity:'0'}, {opacity:'1'}], {duration:700});
          },
          onLeaveBack: () => {
            d.querySelector('.block-works__img .img02-01').classList.add('is-hidden');
          },
        }
      });
      gsap.fromTo('.block-works__img .img02-02', {
        y: '-50%'
      },{ //アニメーションしたい要素を指定
        y: '40%',
        ease: "none",
        scrollTrigger: {
          trigger: '.block-works__img',//アニメーションが始まるトリガーとなる要素
          invalidateOnRefresh: true,
          start: 'top bottom', //アニメーションが始まる位置を指定
          end: 'bottom top',
          scrub: true,
          // markers: true,
          onEnter: () => {
            d.querySelector('.block-works__img.is-img02').classList.remove('is-hidden');
            d.querySelector('.block-works__img.is-img02').animate([{opacity:'0'}, {opacity:'1'}], {duration:700});
          },
          onLeaveBack: () => {
            d.querySelector('.block-works__img .img02-02').classList.add('is-hidden');
          },
        }
      });
    }
  }
  news (selector) {
    const news_block = new GET_ARTICLES(selector);
    news_block.makeItem = (item,content) => `
    <li class="block-${content}-item">
      <a href="/${content}/?mode=detail&amp;article=${item.id}" class="block-${content}-item__link">
        <div class="block-${content}-item__body">
          <div class="date-cate">
            <span class="date">${item.date}</span>&nbsp;|&nbsp;<span class="cate">${item.category.name}</span>
          </div>
          <h4 class="title ${item.is_new? 'is-new':''}">${(mq.matches)? item.title.str_truncate(24) : item.title.str_truncate(40)}</h4>
        </div>
      </a>
    </li>`;
    // news_block.makeItem = (item) => console.log(item);
    news_block.render();
  }
}

const domReady = TPL => {
  new blocks();
};

const jqInit = () => {
  w.jQuery = $

  /****************************
   * movie background
   */
  $.when(
    $.getScript('/assets/js/vendor/jquery.mb.YTPlayer.min.js')
  ).then( () => {
    const $MV = $('[data-plugin="ytplayer"]')
    const param = id => `{
      videoURL: '${id}',
      containment: '.mainvisual__inner',
      mute: true,
      showControls: false,
      autoPlay: true,
      useOnMobile: true,
      showYTLogo: false,
      optimizeDisplay: true,
      abundance: '${(mq.matches)? 0.005: 0.11}',
      stopMovieOnBlur: false,
      loop: true,
      startAt: 0,
      anchor: 'center,center'
    }`.replace(/\n|\s/g,'')
    $MV.attr('data-property',param('lt5g3ra_9Ho'));
    // $MV.attr('data-property',param(''));
    $MV.YTPlayer({
      onReady: function() {
        $('.mainvisual__inner').addClass('is-loaded');
      }
    });
    $MV.YTPApplyFilters({
      brightness: 80
    });
    $MV.YTPPlay();
  });

  // Instagram
  GET_INSTAGRAM();

  //company
  const $banner = $('.block-company__banners .banner');
  const $bg = $('.block-company__bg img');
  const philosophyImg = '/assets/img/home/philosophy_img@2x.jpg';
  const companyImg = '/assets/img/home/company_img@2x.jpg';
  const isHover = w.matchMedia("(any-hover:hover)");
  
  $banner.each((i,v) => {
    $(v).on({
      'mouseenter': () => {
        if( isHover.matches ) {
          if( $(v).hasClass('is-philosophy') ) $bg.attr('src', philosophyImg);
          if( $(v).hasClass('is-company') ) $bg.attr('src', companyImg);
        }
      },
      'touchstart': () => {
        if( !isHover.matches ) {
          if( $(v).hasClass('is-philosophy') ) $bg.attr('src', philosophyImg);
          if( $(v).hasClass('is-company') ) $bg.attr('src', companyImg);
        }
      }
    });
  });

};

export default function HOME () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
  
  $(() => jqInit());
}