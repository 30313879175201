
const [w,d,mq] = [window,document,window.matchMedia( "(max-width: 1023px)" ) ];

class BLOCKS {
  constructor() {
    this.init();
  }
  init () {
    this.message();
  }
  message() {
    w.addEventListener('load', () => {
      setTimeout(() => d.querySelector('.recruit-message-section__catch').classList.add('is-animated'), 500 );
    });
  }
}

export default function RECRUIT_MESSAGE () {
  new BLOCKS();
}