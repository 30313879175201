import Vue from 'vue/dist/vue.esm';
import TAGSORT from '../class/tagsort';
import GET_ARTICLES from '../class/getArticles';

const [w,d,mq] = [window,document,window.matchMedia( "(max-width: 1023px)" ) ];

const categoriesComponent = {
  template: '#categories-template',
  props: {
    categories: Array
  },

  methods : {
    isCurrent(catID) {
      let param = location.search;
      let cateQuery = /cat=\d+?/.test(param) ? parseInt(param.match(/cat=(\d+?)/)[1]) : false;
      return cateQuery == catID;
    }
  }
};

class RELATED_BLOCK {
  constructor(){
    this.related('.people-related-list[data-plugin="getArticles"]')
  }
  related (selector) {
    const related_block = new GET_ARTICLES(selector);
    related_block.makeItem = (item,content) => `
    <li class="${content}-related-item">
      <a href="/${content}/?mode=detail&amp;article=${item.id}" class="${content}-related-item__link">
        <figure class="${content}-related-item__thumb">
          <img src="${item.thumb}" alt="${item.title}" width="100%" loading="lazy">
          <span class="cate">${item.category.name}</span>
        </figure>
        <div class="${content}-related-item__body">
          <span class="desc">${item.desc.str_truncate(40)}</span>
          <h4 class="title ${item.is_new? 'is-new':''}">${(mq.matches)? item.title.str_truncate(24) : item.title.str_truncate(40)}</h4>
        </div>
      </a>
    </li>`;
    // related_block.makeItem = (item) => console.log(item);
    related_block.render();
  }
}

const domReady = TPL => {
  const tagsort = new TAGSORT();
  if ( tagsort.is_detail() ){
    new Vue({
      el: "#people-detail",
      components: {
        categoriesComponent
      },
      data: {
        items: [],
        categories: [],
      },
      created (){
        let content = 'people';
        this.fetchCategories(content);
      },
      methods: {
        fetchCategories (content) {
          fetch(`/assets/api/getCategories/?content=${content}`).then(r => r.json())
          .then(res => this.categories = res||[]);
        }
      }
    });
  }
};

const jqInit = () => {
  new RELATED_BLOCK();
};

export default function PEOPLE () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
  $(() => jqInit());
}