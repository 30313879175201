import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Swiper from "swiper/bundle";
import GET_ARTICLES from "../class/getArticles";
import GET_INSTAGRAM from '../class/getInstagram'
import RECRUIT_MESSAGE from "./recruit-message";

const [w,d,mq] = [window,document,window.matchMedia( "(max-width: 1023px)" ) ];

class HOME_BLOCKS {
  constructor() {
    gsap.registerPlugin(ScrollTrigger);
    this.init();
    w.addEventListener('load', () => {
      this.mainvisual();
      $("a[href='/people/?mode=detail&article=7").find('.cate').text('キャリア');
    });
  }
  init () {
    this.ticker('.recruit-mainvisual-ticker__body[data-plugin="getArticles"]');
    this.message();
    this.businessfield();
    this.people('.block-people__list[data-plugin="getArticles"]');
    this.news('.block-news-recruit__list[data-plugin="getArticles"]');
  }
  ticker (selector) {
    const getArticle = new GET_ARTICLES(selector);
    getArticle.makeItem = item => `
      <a href="${item.href}" class="mainvisual-ticker__link">
        <span class="date">${item.date}</span>
        <strong class="title"><span>${item.title}</span></strong>
      </a>`;
    getArticle.render().then( ([[art,cat]]) => {
      if( art.length < 1 ) {
        d.querySelectorAll( selector ).forEach( obj => {
          let parent = obj.dataset.parent? obj.closest( obj.dataset.parent ): '';
          if(parent) parent.remove();
        });
      }
    });
  }
  mainvisual() {
    const mainvisual = d.querySelector('.recruit-mainvisual');
    mainvisual.classList.add('is-start');
  }
  message() {
    const $message = d.querySelector('.block-message');
    const $message_row = d.querySelector('.block-message__row');

    ScrollTrigger.create ({
      toggleActions: 'play complete resume reverse',
      trigger: '.block-message__row',
      start: 'top center', //アニメーションが始まる位置を指定
      scrub: true,
      toggleClass: {targets: '.block-message__catch', className: "is-animated"},
      once: true,
      // markers: true
    });

    if( !mq.matches ) {
      ScrollTrigger.create({
        trigger: $message_row,
        pin: true,
        start: "top top",
        endTrigger: $message,
        end: "bottom bottom",
        anticipatePin: 1,
        // markers: true,
        scrub: true,
        // snap: {
        //   snapTo: [0.5, 1],
        //   duration: {min: 0.2, max: 1},
        //   ease: "power1.inOut",
        // },
      });

      ScrollTrigger.create({
        trigger: d.querySelector('.block-message__overlay'),
        pin: true,
        start: "top top",
        endTrigger: $message,
        end: "bottom bottom",
        anticipatePin: 1,
        // markers: true,
        scrub: true,
        // snap: {
        //   snapTo: [0.5, 1],
        //   duration: {min: 0.2, max: 1},
        //   ease: "power1.inOut",
        // },
      });

      ScrollTrigger.create({
        trigger: $message,
        start: '50% 55%',
        toggleClass: {targets: '.block-message', className: 'is-dark'},
        // markers: false,
      });
    }
  }
  businessfield() {
    if( mq.matches ) {
      new Swiper('.block-businessfield__list', {
        grabCursor: true,
        slidesPerView: 1.35,
        centeredSlides : true,
        loop: true,
        spaceBetween: 16,
        navigation: {
          nextEl: '.block-businessfield__list .swiper-button-next',
          prevEl: '.block-businessfield__list .swiper-button-prev',
        },
      });
    }
  }
  people (selector) {
    const people_block = new GET_ARTICLES(selector);
    people_block.makeItem = (item,content) => `
    <li class="block-${content}-item">
      <a href="/${content}/?mode=detail&amp;article=${item.id}" class="block-${content}-item__link">
        <figure class="block-${content}-item__thumb">
          <img src="${item.thumb}" alt="${item.title}" width="100%" loading="lazy">
          <span class="cate">新卒採用</span>
        </figure>
        <div class="block-${content}-item__body">
          <span class="desc">${item.desc.str_truncate(40)}</span>
          <h4 class="title ${item.is_new? 'is-new':''}">${(mq.matches)? item.title.str_truncate(24) : item.title.str_truncate(40)}</h4>
        </div>
      </a>
    </li>`;
    // people_block.makeItem = (item) => console.log(item);
    people_block.render();
  }
  news (selector) {
    const news_block = new GET_ARTICLES(selector);
    news_block.makeItem = (item,content) => `
    <li class="block-${content}-item">
      <a href="/${content}/?mode=detail&amp;article=${item.id}" class="block-${content}-item__link">
        <div class="block-${content}-item__body">
          <div class="date-cate">
            <span class="date">${item.date}</span>&nbsp;|&nbsp;<span class="cate">${item.category.name}</span>
          </div>
          <h4 class="title ${item.is_new? 'is-new':''}">${(mq.matches)? item.title.str_truncate(24) : item.title.str_truncate(40)}</h4>
        </div>
      </a>
    </li>`;
    // news_block.makeItem = (item) => console.log(item);
    news_block.render();
  }
}

const domReady = TPL => {
  if( d.body.classList.contains('is--list') ) new HOME_BLOCKS();
  if( d.body.classList.contains('is--article-1') ) RECRUIT_MESSAGE();
};

const jqInit = () => {
  GET_INSTAGRAM();
};

export default function RECRUIT () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
  $(() => jqInit());
  
}